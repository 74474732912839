import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import service from "../../assets/images/servicepic.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import led from "../../assets/images/service/ledekran.png";
import { useParams } from "react-router";
import { Autoplay } from "swiper";
import axios from "axios";
import "./index.scss";
function Service() {
    let { id } = useParams();
    const [construction, setConstruction] = useState([]);
    const [construction_images, setConstruction_images] = useState([]);
    const [construction_detail, setConstruction_detail] = useState([]);
    const [current__img, setCurrent__img] = useState([]);
    const [current, setCurrent] = useState(0);
    const [loadImg, setLoadImg] = useState(false);

    useEffect(() => {
        axios
            .get(
                `https://api.medialux.uz/v1/api/main/constructor-details/${id}/`
            )
            .then((res) => {
                setConstruction(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    useEffect(() => {
        axios
            .get(`https://api.medialux.uz/v1/api/main/constructor-image/${id}/`)
            .then((res) => {
                setConstruction_images(res.data);
                setConstruction_detail([
                    res.data[current].const_format,
                    res.data[current].address,
                ]);
                setCurrent__img(res.data[current].images);
            })

            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        setCurrent__img(construction_images[current]?.images);
        setLoadImg(false)
    }, [loadImg])

    function changeData(key) {
        setCurrent__img(construction_images[key]?.images);

        setConstruction_detail([
            construction_images[key].const_format,
            construction_images[key].address,
        ]);
        setCurrent(key)
    }

    function goleft() {
        if (current > 0) {
            setCurrent(current - 1);
            setConstruction_detail([
                construction_images[current].const_format,
                construction_images[current].address,
            ]);
            setLoadImg(true)
            
        }
    }

    function goright() {
        if (current < construction_images.length-1) {
            setCurrent(current + 1);
            setConstruction_detail([
                construction_images[current].const_format,
                construction_images[current].address,
            ]);
            setLoadImg(true)
        }
    }

    return (
        <div>
            <Header></Header>

            <section className="single-service">
                <div className="info-section-img">
                    <img
                        src={`https://api.medialux.uz${construction?.main_img}`}
                        alt=""
                    />
                </div>

                <div className="info-section">
                    <div className="service-title">{construction.title}</div>
                    {construction.description}
                </div>
            </section>
            <div className="custom__slide">
                <button className="gobtn" onClick={() => goleft()}>
                    <i className="fa fa-angle-left"></i>
                </button>
                <img
                    src={`https://api.medialux.uz/${current__img}`}
                    alt=""
                    height="700px"
                    width="1000px"
                />
                <button className="gobtn" onClick={() => goright()}>
                    <i className="fa fa-angle-right"></i>
                </button>
            </div>

            <div className="imgs__and__info">
                <div className="one__service__info">
                    <div>Address : {construction_detail[1]}</div>
                    <div>Format : {construction_detail[0]}</div>
                </div>

                <div className="imgs">
                    <Swiper
                        modules={[Autoplay]}
                        slidesPerView={4}
                        autoplay
                        className="sviper"
                        breakpoints={{
                            375: {
                                width: 335,
                                slidesPerView: 1,
                            },
                        }}
                    >
                        {construction_images.map((image, key) => (
                            <SwiperSlide
                                className="slide-item"
                                onClick={() => changeData(key)}
                            >
                                <img
                                    src={`https://api.medialux.uz/${image.images}`}
                                    height="209"
                                    width="297px"
                                    style={{ borderRadius: "37px" }}
                                    alt=""
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
            <section className="price-section">
                <div className="price">
                    <div className="price-title">{construction.sub_title1}</div>
                    <div className="text">
                        <pre>{construction.subprice_1}</pre>
                    </div>
                </div>
                <div className="price">
                    <div className="price-title">{construction.sub_title2}</div>
                    <div className="text">
                        <pre>{construction.subprice_2}</pre>
                    </div>
                </div>
            </section>

            <div className="map-title">Место нахождения конструкции</div>
            <div className="map map-bg">
                <iframe
                    className="map"
                    src="https://www.google.com/maps/d/u/0/embed?mid=10-GI8tV-LkiYWQqM3fWiL7_B6JyzvZV_&ehbc=2E312F"
                    width="1385px"
                    style={{ border: 0, marginTop: "50px" }}
                    height="742px"
                ></iframe>
            </div>
            <Footer></Footer>
        </div>
    );
}

export default Service;
