import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import w1 from "../../assets/images/ourworks/w1.png";
import w2 from "../../assets/images/ourworks/w2.png";
import w3 from "../../assets/images/ourworks/w3.png";
import w4 from "../../assets/images/ourworks/w4.png";
import w5 from "../../assets/images/ourworks/w5.png";
import w6 from "../../assets/images/ourworks/w6.png";
import "./index.scss";
import details from "../../assets/images/details.png";
import { Link } from "react-router-dom";
import axios from "axios";

function OurWorks() {
    const [works, setWorks] = useState([]);

    useEffect(() => {
      axios.get('https://api.medialux.uz/v1/api/main/portfolio-list/')
        .then(res => {
            setWorks(res.data)
        })
    
    }, [])

    function filter__works(num) {
        var filter_word = document.getElementById(`work__title${num}`).innerText

        axios.get(`https://api.medialux.uz/v1/api/main/portfolio-filter/${filter_word}/`)
        .then(res => {
            setWorks(res.data)
        })
    }
    
    return (
        <div>
            <Header></Header>

            <div className="ourworks">
                <center className="title">Наши работы</center>

                <div className="works__box">
                    <div className="work__item" onClick={() => filter__works(1)}>
                        <img src={w1} alt="" className="left__img" />
                        <div className="info__section left">
                            <div className="work__title" id="work__title1">Брендинг</div>
                        </div>
                    </div>
                    <div className="work__item" onClick={() => filter__works(2)}>
                        <img src={w2} alt="" />
                        <div className="info__section">
                            <div className="work__title" id="work__title2">Видео</div>
                        </div>
                    </div>
                    <div className="work__item" onClick={() => filter__works(3)}>
                        <img src={w3} alt="" className="left__img" />
                        <div className="info__section left">
                            <div className="work__title" id="work__title3">
                                Продукци и упаковка
                            </div>
                        </div>
                    </div>
                    <div className="work__item" onClick={() => filter__works(4)}>
                        <img src={w4} alt="" />
                        <div className="info__section">
                            <div className="work__title" id="work__title4">Вэб дизайн и СММ</div>
                        </div>
                    </div>
                    <div className="work__item" onClick={() => filter__works(5)}>
                        <img src={w5} alt="" className="left__img" />
                        <div className="info__section left">
                            <div className="work__title" id="work__title5">Все проекты</div>
                        </div>
                    </div>
                    <div className="work__item" onClick={() => filter__works(6)}>
                        <img src={w6} alt="" />
                        <div className="info__section">
                            <div className="work__title" id="work__title6">Стоки</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="image__box">
                { works.map((work,index) => 
                <Link to={`/work/${work.id}`}>
                    <img src={`https://api.medialux.uz${work.main_img}`} alt="" />
                </Link>)}
            </div>

            <Footer></Footer>
        </div>
    );
}

export default OurWorks;
