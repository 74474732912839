import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import details from "../../assets/images/details.png";
import "./index.scss";
import { useParams } from "react-router";
import axios from "axios";

function Work() {
    const [work, setWork] = useState([]);
    const [load, setLoad] = useState(false);

    const id = useParams().id;

    useEffect(() => {
        axios
            .get(`https://api.medialux.uz/v1/api/main/portfolio-details/${id}/`)
            .then((res) => {
                setWork(res.data);
                setLoad(true);
            });
    }, []);
    return (
        <div>
            <Header></Header>

            <div className="work__detail">
                <center className="title">
                    <div>{work?.parent?.title}</div>
                    <div className="sub__title">{work?.parent?.sub_title}</div>
                </center>

                <img
                    src={`https://api.medialux.uz${work?.parent?.main_img}`}
                    className="main__image"
                    alt=""
                />

                <div className="bottom__section">

                    <div className="items__box">
                        { load
                            ? work.childs.map((child_work, key) => 
                                  <div className="items">
                                      <div className="item__title">
                                          {child_work.title}
                                      </div>
                                      <div className="item__description">
                                      {child_work.sub_title}
                                      </div>
                                      <img src={`https://api.medialux.uz${child_work.main_img}`} alt="" />
                                  </div>
                              )
                            : ""}
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </div>
    );
}

export default Work;
