import React from "react";
import { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import led from "../../assets/images/service/ledekran.png";
import city from "../../assets/images/service/cityformat.png";
import supersayt from "../../assets/images/service/supersayt.png";
import priz from "../../assets/images/service/prizmatron.png";
import breand from "../../assets/images/service/brentmaur.png";
import krish from "../../assets/images/service/krishnie.png";
import { Link } from "react-router-dom";

import axios from "axios";

import "./index.scss";

function Constructions() {
    const [constructions, setConstructions] = useState([]);

    useEffect(() => {
        axios
            .get(
                "https://api.medialux.uz/v1/api/main/constructor-list/"
            )
            .then((res) => {
                setConstructions(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <div>
            <Header></Header>

            <div className="news-title" style={{ width: "1216px" }}>
                Мы предлагаем более 1 тыс. поверхностей для вашей рекламы
            </div>

            <div className="services-box">
                {constructions.map((construction, key) => (
                    <Link
                        to={`/service/${construction.id}`}
                        className="service-item"
                    >
                        <div className="img-box">
                            <div
                                style={{
                                    background: `url(https://api.medialux.uz${construction.main_img})`,
                                }}
                                className="services-image"
                            ></div>
                        </div>
                        <div className="text">
                            {construction.title} {construction.price}
                        </div>
                    </Link>
                ))}
            </div>

            <Footer></Footer>
        </div>
    );
}

export default Constructions;
